import React from 'react';
import CustomError from '@components/CustomError';
import { NextPage } from 'next';
import Main from '@components/Layout/Main';
import { NeedHelpSection } from '@components/NeedHelpSection';
import { useTranslations } from '@hooks/useTranslations';
import FAQs from '@components/FAQs';
import routes from '../constants/routes';

export const NOT_FOUND_CODE = 404;

const NotFoundErrorPage: NextPage = () => {
    const { t } = useTranslations();

    return (
        <Main>
            <CustomError
                errorType={String(NOT_FOUND_CODE)}
                returnLink={routes.ROOT}
                returnLinkLabel={t('errorPage.returnLink.label')}
                titleDescription={t('errorPage.notFound.description')}
            />
            <NeedHelpSection />
            <FAQs />
        </Main>
    );
};

export const getInitialProps = () => {
    return {
        namespacesRequired: ['common'],
    };
};

export default NotFoundErrorPage;
